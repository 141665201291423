<template>
  <section>
    <validation-observer :vid="id" ref="addressObserver" slim>
      <div v-if="computedOptions.showName" :class="rowCSSClass">
          <label for="updateAdressName" :class="labelCSSClass">{{ computedLabels.name }}</label>
          <div :class="fieldCSSClass">
            <validation-provider ref="nameProvider" name="name" mode="eager" v-slot="{ errors, classes }" slim>
              <input id="updateAdressName" type="text" :placeholder="computedLabels.name_placeholder" :class="{...inputCSSClass,...classes}" v-model="input.name" />
              <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>
            </validation-provider>
          </div>
      </div>
      <div v-for="index in nbAddressLines" :key="index" :class="rowCSSClass">
          <label v-if="index==1" :for="'updateAddressLine_'+index" :class="labelCSSClass">{{ getAddressLineLabel(index-1) }} <small v-if="required">*</small></label>
          <div v-else :class="labelCSSClass"></div>
          <div :class="fieldCSSClass">
            <validation-provider ref="addressLinesProvider" v-if="computedOptions.addressAutoComplete && index==1" name="address" mode="eager" rules="required" v-slot="{ errors, classes }" slim>
              <vue-autosuggest
                v-model="input.addressLines[0]"
                :suggestions="addressSuggestions"
                :class="{...classes}"
                :component-attr-id-autosuggest="'address-autosuggest-'+index"
                :get-suggestion-value="getAddressSuggestionValue"
                :render-suggestion="renderAddressSuggestionValue"
                @selected="onAddressSuggestionSelected"
                @input="onAddressInputChange($event)"
                :input-props="{id: 'updateAddressLine_'+index, placeholder:getAddressLinePlaceholder(index-1), class:{'autosuggest__input':true, ...inputCSSClass , 'required':required, ...classes}, autocomplete:'billing-address', required:required}"
              >
                <template slot="after-input">
                  <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>
                </template>
              </vue-autosuggest>
            </validation-provider>
            <validation-provider ref="addressLinesProvider" v-else name="address" mode="eager" v-slot="{ errors, classes }" slim>
              <input :id="'updateAddressLine_'+index" type="text" :placeholder="getAddressLinePlaceholder(index-1)" :class="{...inputCSSClass,...classes}" v-model="input.addressLines[index - 1]" />
              <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>
            </validation-provider>
          </div>
      </div>
      <TextField 
          ref="postalCode"
          v-bind:value.sync="input.postalCode" 
          id="postalCode" 
          :label="computedLabels.postalCode" 
          :placeholder="computedLabels.postalCode_placeholder" 
          :rowClass="rowCSSClass" 
          :inputClass="inputCSSClass" 
          :labelAsColumn="labelAsColumn"
          :required="required"
      />
      <TextField 
          ref="city"
          v-bind:value.sync="input.city" 
          id="city" 
          :label="computedLabels.city" 
          :placeholder="computedLabels.city_placeholder" 
          :rowClass="rowCSSClass" 
          :inputClass="inputCSSClass" 
          :labelAsColumn="labelAsColumn"
          :required="required"
      />
      <Country
        ref="country"
        v-bind:value.sync="input.countryCode" 
        id="updateCountry"
        :rowClass="rowCSSClass" 
        :inputClass="inputCSSClass"
        :labelAsColumn="labelAsColumn"
        :label="computedLabels.country"
        :placeholder="computedLabels.country_select"
        :required="required"
        :type="computedOptions.listCountries"
      />
      <TextField 
          v-if="computedOptions.showNote"
          ref="note"
          v-bind:value.sync="input.note" 
          id="note" 
          :label="computedLabels.note" 
          :placeholder="computedLabels.note_placeholder" 
          :rowClass="rowCSSClass" 
          :inputClass="inputCSSClass" 
          :labelAsColumn="labelAsColumn"
          :required="false"
      />
      <TextField 
          ref="lat"
          v-if="computedOptions.showGeolocation"
          v-bind:value.sync="input.lat" 
          id="lat" 
          :label="computedLabels.lat" 
          :placeholder="computedLabels.lat_placeholder" 
          :rowClass="rowCSSClass" 
          :inputClass="inputCSSClass" 
          :labelAsColumn="labelAsColumn"
          :required="false"
      />
      <TextField 
          ref="lng"
          v-if="computedOptions.showGeolocation"
          v-bind:value.sync="input.lng" 
          id="lng" 
          :label="computedLabels.lng" 
          :placeholder="computedLabels.lng_placeholder" 
          :rowClass="rowCSSClass" 
          :inputClass="inputCSSClass" 
          :labelAsColumn="labelAsColumn"
          :required="false"
      />
    </validation-observer>
  </section>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';
import Country from './Country.vue';
import TextField from './TextField.vue';
import * as api from '@fwk-client/utils/api';

interface AddressOptions {
  showName:boolean,
  showNote:boolean,
  nbAddressLines:number,
  addressAutoComplete:boolean,
  showGeolocation:boolean,
  listCountries:"EU"|"WORLD"
}

interface AddressLabels {
  name:string;
  name_placeholder:string;
  addressLines:string[];
  addressLines_placeholder:string[];
  postalCode:string;
  postalCode_placeholder:string;
  city:string;
  city_placeholder:string;
  country:string;
  country_select:string;
  note:string;
  note_placeholder:string;
  lat:string;
  lat_placeholder:string;
  lng:string;
  lng_placeholder:string;
}

export interface AddressInput {
  name?: string,
  addressLines : string[],
  postalCode : string,
  city : string,
  countryCode : string,
  note? : string,
  lat: number,
  lng: number
}

@Component({
  components: { 
    Country,
    TextField
  }
})
export default class Address extends mixins<GenericInput<AddressInput>>(GenericInput) {

  @Prop({
    type: Object,
    required: false,
  }) readonly options!: AddressOptions | undefined

  @Prop({
    type: Object,
    required: false,
  }) readonly labels!: AddressLabels | undefined

  computedOptions:AddressOptions = {
    showName: true,
    showNote: true,
    nbAddressLines: 3,
    addressAutoComplete: true,
    showGeolocation: false,
    listCountries: "EU",
    ...this.options
  };

  get computedLabels():AddressLabels {
    if(this.labels) {
      return {
        name:'',
        name_placeholder:'',
        addressLines:[''],
        addressLines_placeholder:[''],
        postalCode:'',
        postalCode_placeholder:'',
        city:'',
        city_placeholder:'',
        country:'',
        country_select:'',
        note:'',
        note_placeholder:'',
        lat:'',
        lat_placeholder:'',
        lng:'',
        lng_placeholder:'',
        ...this.labels as any
      }
    }
    else {
      return {
        name:this.$t('address.form.name') as string,
        name_placeholder:this.$t('address.form.name_placeholder') as string,
        addressLines:[
          this.$t('address.form.addressLine') as string
        ],
        addressLines_placeholder:[
          this.$t('address.form.addressLine_placeholder') as string
        ],
        postalCode:this.$t('address.form.postalCode') as string,
        postalCode_placeholder:this.$t('address.form.postalCode_placeholder') as string,
        city:this.$t('address.form.city') as string,
        city_placeholder:this.$t('address.form.city_placeholder') as string,
        country:this.$t('address.form.country') as string,
        country_select:this.$t('address.form.country_select') as string,
        note:this.$t('address.form.note') as string,
        note_placeholder:this.$t('address.form.note_placeholder') as string,
        lat:this.$t('address.form.lat') as string,
        lat_placeholder:this.$t('address.form.lat_placeholder') as string,
        lng:this.$t('address.form.lng') as string,
        lng_placeholder:this.$t('address.form.lng_placeholder') as string,
      }
    }
  }

  nbAddressLines:number = this.value && this.value.addressLines ? Math.max(this.value.addressLines.length, this.computedOptions.nbAddressLines) : this.computedOptions.nbAddressLines;

  addressSuggestions:any = [];

  input = this.value ? {
    name: this.value.name,
    addressLines : this.value.addressLines,
    postalCode : this.value.postalCode,
    city : this.value.city,
    countryCode : this.value.countryCode ? this.value.countryCode.toUpperCase() : '',
    note: this.value.note,
    lat: this.value.lat,
    lng: this.value.lng
    } : {
    name : '',
    addressLines : this.defaultAddressLines,
    postalCode : '',
    city : '',
    countryCode : '',
    note: '',
    lat: '',
    lng: ''
  };

  validationTimer:any = null;
  isValid:boolean = false;

  mounted() {
    // We check if fields are prefilled to validate them directly
    if(this.input.name != "" && this.$refs.nameProvider) {
      // @ts-ignore
      this.$refs.nameProvider.validate();
    }
    for(var index = 0 ; index < this.input.addressLines.length ; index++) {
      if(this.input.addressLines[index]!="" && this.$refs.addressLinesProvider) {
        // @ts-ignore
        this.$refs.addressLinesProvider[index].validate();
      }
    }
    if(this.input.postalCode != "" && this.$refs.postalCode) {
      // @ts-ignore
      this.$refs.postalCode.validate();
    }
    if(this.input.city != "" && this.$refs.city) {
      // @ts-ignore
      this.$refs.city.validate();
    }
    if(this.input.countryCode != "" && this.$refs.country) {
      // @ts-ignore
      this.$refs.country.validate();
    }
    if(this.input.lat != "" && this.$refs.lat) {
      // @ts-ignore
      this.$refs.lat.validate();
    }
    if(this.input.lng != "" && this.$refs.lng) {
      // @ts-ignore
      this.$refs.lng.validate();
    }
    this.validateAddress()
  }

  get defaultAddressLines():string[] {
    var lines = [];
    for(var i = 0 ; i < this.computedOptions.nbAddressLines ; i++) {
      lines.push('');
    }
    return lines;
  }

  getAddressLineLabel(index:number):string {
    if(this.computedLabels.addressLines.length == 1) {
      return this.computedLabels.addressLines[0];
    }
    return this.computedLabels.addressLines[index];
  }

  getAddressLinePlaceholder(index:number):string {
    if(this.computedLabels.addressLines_placeholder.length == 1) {
      return this.computedLabels.addressLines_placeholder[0];
    }
    return this.computedLabels.addressLines_placeholder[index];
  }

  onAddressInputChange(text:string) {
    // We need to get the list of addresses based on what has been entered by the end user
    if(text.length < 4) {
      this.addressSuggestions = [];  
      return;
    }

    var options:api.ApiVueOptions =  {
      app: this
    }
    var input:any = {
      address: { ...this.input }
    }
    api.postAPI('/api/utils/getAddressSuggestions', input, options).then((response:any) => {
      if(response.suggestions) {  
        this.addressSuggestions = [{data:response.suggestions}]; 
      }
      else {
        this.addressSuggestions = [];
      }
    });    
  }

  getAddressSuggestionValue(suggestion:any) {
    return suggestion.item.street;
  }

  countryCodeMapping:any = {
    'MC' : 'FR',
    'NC' : 'FR'
  }

  onAddressSuggestionSelected(suggestion:any) {
    var place_id = suggestion.item.place_id;

    var options:api.ApiVueOptions =  {
      app: this
    }
    var input:any = {
      place_id: place_id
    }

    api.postAPI('/api/utils/getAddressDetails', input, options).then((response:any) => {
      if(response.details) {
        var newAddress:any = {
          name: this.input.name,
          addressLines : response.details.addressLines,
          postalCode : response.details.postalCode,
          city : response.details.city
        }
        
        //  We update the country code using mapping.
        var countryCode = response.details.countryCode.toUpperCase();
        if(this.countryCodeMapping[countryCode]) {
          countryCode = this.countryCodeMapping[countryCode];
        }
        newAddress.countryCode = countryCode;

        // We check if we show geolocation
        if(this.computedOptions.showGeolocation) {
          newAddress.lat = response.details.lat;
          newAddress.lng = response.details.lng;
        }

        Vue.set(this,"input", newAddress);
      }
    }); 
  }

  renderAddressSuggestionValue(suggestion:any) {
    var substrings = suggestion.item.matched_substrings;
    var slices = [];
    var text = suggestion.item.label;
    var index = 0;
    for(var match of substrings) {
      if(match.offset > index) {
        slices.push(text.slice(index, match.offset));
      }
      var slice = text.slice(match.offset, match.offset+match.length);
      slices.push(this.$createElement('b', slice));
      index = match.offset+match.length;
    }
    if(index < text.length) {
      slices.push(text.slice(index));
    }
    
    
    return this.$createElement('span',slices);
  }
  
  @Watch('input', { deep: true })
  onChange(val: any, oldVal: any) {
      clearTimeout(this.validationTimer);
      this.validationTimer = setTimeout(() => {
        // We need to wait that components are updated with new information
        Vue.nextTick(() => {
          this.validateAddress();
        })
      }, 800)
      ;
  }

  validateAddress() {
    if(this.$refs.addressObserver) {
      // @ts-ignore
      this.$refs.addressObserver.validate({silent:true}).then((isValid:boolean) => {
        this.isValid = isValid;
        this.$emit('valid', isValid);
      })
    }
  }
}
</script>